<template>
    <div id="app">
        <div id="nav">
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link> |
            <router-link to="/register">Register</router-link> |
            <router-link to="/login">Login</router-link> |
            <button @click="logout">Logout</button>
        </div>
        <router-view />
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
    name: 'App',
    methods: {
        async logout() {
            try {
                await Auth.signOut();
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

input {
    margin-right: 10px;
}
</style>
